import { reactive, toRefs, defineAsyncComponent, nextTick, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useDebounce } from '@/hooks/core/useDebounce';
import API from "@/api/authority/index";
import { ElMessageBox } from "element-plus";
import { useMessage } from '@/hooks/web/useMessage';
import { Search, Plus } from "@element-plus/icons-vue";
import { formatDate } from '@/utils/formatTime';
export default {
  name: "Role",
  components: {
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue')),
    BasicTemplate: defineAsyncComponent(() => import('@/components/BasicTemplateTablePage/index.vue')),
    AddRole: defineAsyncComponent(() => import('./components/AddRole.vue'))
  },
  setup() {
    const message = useMessage();
    const store = useStore();
    const refData = reactive({
      addRoleShow: false,
      loading: false,
      total: 0,
      current: 1,
      pageSize: 100,
      searchForm: {
        name: '',
        deptId: null
      },
      tableData: [],
      tableColumns: [{
        title: '序号',
        align: 'center',
        key: 'index',
        width: 60,
        slots: {
          customRender: 'index'
        },
        visible: true
      }, {
        title: '角色名称',
        align: 'center',
        key: 'name',
        visible: true
      }, {
        title: '角色描述',
        align: 'center',
        key: 'remark',
        visible: true
      }, {
        title: '创建时间',
        align: 'center',
        key: 'createTime',
        visible: true
      }, {
        fixed: 'right',
        title: '操作',
        align: 'center',
        key: 'opereat',
        width: 200,
        slots: {
          customRender: 'opereat'
        },
        visible: true
      }],
      activeRoleId: null,
      activeOrg: {
        orgId: null,
        orgName: '',
        activeValue: null
      }
    });
    const id = computed(() => store.state.treeData.length ? store.state.treeData[0][0].id : null);
    // 查询
    const onSearch = async () => {
      refData.loading = true;
      const params = {
        deptId: refData.searchForm.deptId,
        name: refData.searchForm.name,
        pageSize: refData.pageSize,
        pageNo: refData.current
      };
      try {
        const {
          data,
          msg,
          code
        } = await API.getRoleTable(params);
        refData.total = data.total;
        refData.tableData = data.list;
        if (data.list) {
          refData.tableData = data.list.map(item => {
            item.createTime = formatDate(item.createTime);
            return item;
          });
        }
        refData.loading = false;
      } catch (error) {
        refData.loading = false;
      }
    };
    watch(() => id.value, val => {
      nextTick(() => {
        val && (refData.searchForm.deptId = val, debounceOnSearch());
      });
    });
    nextTick(() => {
      refData.searchForm.deptId = store.state.treeData.length ? store.state.treeData[0][0].id : null, debounceOnSearch();
    });
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    // 分页
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    // 页码
    const pageSizeChange = val => {
      refData.pageSize = val;
      debounceOnSearch();
    };
    //点击结构树
    const handleClick = data => {
      refData.searchForm.deptId = data.id;
      refData.activeOrg.orgId = data.id;
      refData.activeOrg.orgName = data.name;
      refData.activeOrg.activeValue = data.typeId;
      debounceOnSearch();
    };
    //修改角色
    const handleEdit = async roleId => {
      refData.activeRoleId = roleId;
      refData.addRoleShow = true;
    };
    //删除角色
    const handleDel = async (id, name) => {
      ElMessageBox.confirm(`是否确认删除“${name}”该角色`, '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const {
          code,
          msg
        } = await API.deleteRole({
          id
        });
        code == 0 && debounceOnSearch();
        message[code == 0 ? 'success' : 'warning'](code == 0 ? '删除成功' : msg);
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleClick,
      handleDel,
      handleEdit,
      Search,
      Plus
    };
  }
};